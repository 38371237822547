<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
  title: {
    type: String,
    required: true,
    default: '',
  },
  showCloseButton: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['close'])
</script>

<template>
  <div class="flex h-20 items-center px-4 text-xl tracking-wide">
    {{ props.title }}
    <BaseButton
      v-if="showCloseButton"
      variant="icon"
      class="ml-auto"
      @click="emit('close')"
    >
      <XMarkIcon class="size-6" />
    </BaseButton>
  </div>
</template>
