<script setup lang="ts">
const emit = defineEmits(['change'])
const { t } = useI18n()
const selectedMenuItem = ref(1)
interface MenuItem { id: number, labelKey: string, content: string, icon: string }
const menuItems = ref<MenuItem[]>([
  {
    id: 1,
    labelKey: 'general',
    content: 'General',
    icon: 'AdjustmentsVerticalIcon',
  },
])
onBeforeMount(() => {
  emit('change', menuItems.value[0])
})
function changeSelectedItem(item: MenuItem) {
  selectedMenuItem.value = item.id
}
</script>

<template>
  <div
    class="flex h-full w-[200px] shrink-0 flex-col rounded-l-md bg-modal-dialog-navigation"
  >
    <SettingsHeader :title="t('settings')" />
    <SettingsNavbarItems
      v-for="item in menuItems"
      :key="item.id"
      :item="item"
      :class="item.id === selectedMenuItem ? 'bg-modal-dialog-navigation-active' : ''"
      @change="emit('change', item), changeSelectedItem(item)"
    />
  </div>
</template>
