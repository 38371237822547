import type { FetchError } from 'ofetch'
import type { Ref } from 'vue'
import { ACCESS_TOKEN_LOCALSTORAGE_KEY } from '~/constants'

interface TokenResponse {
  access: string
  refresh: string
}

const accessToken = useLocalStorage(ACCESS_TOKEN_LOCALSTORAGE_KEY, '')
export const authorizationHeader = computed(() => `Bearer ${accessToken.value}`)
const isLoggedIn = computed(() => isJwtTokenValid(accessToken.value))
const isLoggingIn = ref(false)
const thrownError = ref('')

export function useAuth() {
  const router = useRouter()

  /** Send user credentials to the backend and retrieves user tokens, which will be stored in localStorage */
  const login = async (username: string | Ref<string>, password: string | Ref<string>) => {
    if (isLoggingIn.value)
      return

    username = unref(username)
    password = unref(password)
    thrownError.value = ''
    isLoggingIn.value = true

    let response, error
    try {
      response = await $fetch<TokenResponse>('/api/token/', {
        method: 'POST',
        body: {
          username,
          password,
        },
      })
    } catch (err) {
      error = err as FetchError
    } finally {
      isLoggingIn.value = false
    }

    // handle errors and empty responses
    if (error || !response) {
      if (error?.statusCode === 401)
        thrownError.value = 'credentialsInvalid'
      else
        thrownError.value = 'unknownError'
      return
    }

    // login was successful
    accessToken.value = response.access
    const { redirectedFrom } = useRoute()
    if (redirectedFrom)
      await router.push(redirectedFrom.fullPath)
    else
      await router.push('/')
  }

  const logout = async () => {
    accessToken.value = ''
    await router.push('/login')
  }

  return {
    login,
    logout,
    accessToken: readonly(accessToken),
    isLoggingIn: readonly(isLoggingIn),
    isLoggedIn: readonly(isLoggedIn),
    thrownError: readonly(thrownError),
  }
}
