<script setup lang="ts">
import type { NuxtError } from '#app'

defineProps({
  error: Object as () => NuxtError,
})

function goToHome() {
  clearError({ redirect: '/' })
}
</script>

<template>
  <div class="center h-screen gap-4">
    <div class="text-9xl font-semibold text-danger">
      {{ error?.statusCode }}
    </div>
    <div class="space-y-2">
      <div class="flex items-center gap-x-2">
        <Outline24ExclamationCircleIcon
          v-if="error?.fatal"
          class="size-6 text-danger"
        />
        <Outline24ExclamationTriangleIcon
          v-else
          class="size-6 text-danger"
        />
        <div class="text-xl font-medium">
          Oops!
        </div>
      </div>
      <div class="truncate">
        {{ error?.statusMessage }}
      </div>
      <BaseButton
        variant="text"
        @click="goToHome"
      >
        Go to home
      </BaseButton>
    </div>
  </div>
</template>
