export default defineNuxtRouteMiddleware((to) => {
  const { isLoggedIn } = useAuth()

  const isOnLoginPage = (to.name === 'login')
  if (isOnLoginPage && isLoggedIn.value)
    return navigateTo('/')

  if (!isOnLoginPage && !isLoggedIn.value)
    return navigateTo('/login')
})
