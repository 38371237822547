<script setup lang="ts">
const emit = defineEmits(['closed'])

const selectedMenuItem = ref({ content: '', labelKey: '' })
const isVisible = ref(true)

function close() {
  isVisible.value = false
}

function changeContent(menuItem: any) {
  selectedMenuItem.value = menuItem
}
</script>

<template>
  <BaseModal
    v-model="isVisible"
    class="!p-0"
    no-footer
    @closed="emit('closed')"
  >
    <div class="flex h-[600px] w-[800px]">
      <SettingsNavbar @change="changeContent" />
      <SettingsContent
        v-if="selectedMenuItem.content"
        :content="selectedMenuItem.content"
        :label-key="selectedMenuItem.labelKey"
        @close="close()"
      />
    </div>
  </BaseModal>
</template>
