import { acceptHMRUpdate, defineStore } from 'pinia'
import { RAW_SETTINGS_OPTIONS } from '~/constants'
import SAVE_USER_SETTING from '~/graphql/mutations/saveUserSetting.gql'

export const useUserStore = defineStore('user', () => {
  const user = ref<User | undefined>(undefined)
  const rawSettings = reactive<Partial<Record<SettingsKeys, string>>>({})

  const timezone = computed<string>({
    get() {
      if (_isSetting('TIMEZONE', rawSettings.TIMEZONE))
        return rawSettings.TIMEZONE
      return 'UTC+00:00'
    },
    set(newValue) {
      _saveSetting('TIMEZONE', newValue)
    },
  })

  const notifications = computed<string>({
    get() {
      if (_isSetting('NOTIFICATIONS', rawSettings.NOTIFICATIONS))
        return rawSettings.NOTIFICATIONS
      return 'info'
    },
    set(newValue) {
      _saveSetting('NOTIFICATIONS', newValue)
    },
  })

  const showNumberOfUnfinishedTasks = computed<boolean>({
    get() {
      if (
        _isSetting(
          'SHOW_NUMBER_OF_UNFINISHED_TASKS',
          rawSettings.SHOW_NUMBER_OF_UNFINISHED_TASKS,
        )
      )
        return rawSettings.SHOW_NUMBER_OF_UNFINISHED_TASKS !== '0'
      return true
    },
    set(newValue) {
      rawSettings.SHOW_NUMBER_OF_UNFINISHED_TASKS = newValue ? '1' : '0'
    },
  })

  const language = computed<string>({
    get() {
      if (_isSetting('LANGUAGE', rawSettings.LANGUAGE))
        return rawSettings.LANGUAGE
      return 'en'
    },
    set(newValue) {
      _saveSetting('LANGUAGE', newValue)
    },
  })

  const theme = computed<string>({
    get() {
      if (_isSetting('THEME', rawSettings.THEME))
        return rawSettings.THEME
      return 'system'
    },
    set(newValue) {
      _saveSetting('THEME', newValue)
    },
  })

  const dataBindingTableOrientation = computed<string>({
    get() {
      if (
        _isSetting(
          'DATA_BINDING_TABLE_ORIENTATION',
          rawSettings.DATA_BINDING_TABLE_ORIENTATION,
        )
      )
        return rawSettings.DATA_BINDING_TABLE_ORIENTATION

      return 'horizontal'
    },
    set(newValue) {
      _saveSetting('DATA_BINDING_TABLE_ORIENTATION', newValue)
    },
  })

  const openDashboardAccordion = computed<number>({
    get() {
      if (
        _isSetting(
          'OPEN_DASHBOARD_ACCORDION',
          rawSettings.OPEN_DASHBOARD_ACCORDION,
        )
      )
        return Number(rawSettings.OPEN_DASHBOARD_ACCORDION)
      return DashboardAccordions.DNC_OPTIMIZATIONS
    },
    set(newValue) {
      _saveSetting('OPEN_DASHBOARD_ACCORDION', newValue.toString())
    },
  })

  function setUserFromResponse(response: User) {
    user.value = response
  }
  function setSettingsFromResponse(
    response: { key: SettingsKeys, value: string }[],
  ) {
    for (const item of response) rawSettings[item.key] = item.value
  }

  const { mutate, onError } = useMutation(SAVE_USER_SETTING)
  onError(error => console.error(error))
  function _saveSetting(setting: SettingsKeys, value: string) {
    rawSettings[setting] = value
    mutate({ key: setting, value })
  }

  return {
    user: readonly(user),
    setUserFromResponse,
    setSettingsFromResponse,

    timezone,
    notifications,
    showNumberOfUnfinishedTasks,
    language,
    theme,
    dataBindingTableOrientation,
    openDashboardAccordion,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))

function _isSetting(setting: SettingsKeys, value: any): value is string {
  if (!value)
    return false
  const options = RAW_SETTINGS_OPTIONS[setting]
  if (!options)
    return true
  return options.includes(value)
}
