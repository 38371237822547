<script setup lang="ts">
const { locale } = useI18n()

useHead({
  htmlAttrs: {
    lang: locale,
  },
})
</script>

<template>
  <div class="h-dvh w-dvw">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <NotificationsSelector />
  </div>
</template>
