export interface DecodedToken {
  token_type: 'access' | 'refresh'
  exp: number
  iat: number
  jti: string
  user_id: string
}

// Source: https://stackoverflow.com/a/38552302
export function decodeJwtToken(token: string): DecodedToken | undefined {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      const code = (`00${c.charCodeAt(0).toString(16)}`).slice(-2)
      return `%${code}`
    }).join(''))
    return JSON.parse(jsonPayload)
  } catch {}
}

export function isJwtTokenValid(token: string | undefined) {
  if (!token)
    return false
  const decodedToken = decodeJwtToken(token)
  if (!decodedToken)
    return false
  const now = new Date().getTime()
  return decodedToken.exp * 1000 > now
}
