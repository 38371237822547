<script setup lang="ts">
// call `useI18n`, and spread `t` from  `useI18n` returning
const emit = defineEmits(['close'])
const { t } = useI18n()
</script>

<template>
  <div class="flex h-20 items-center justify-end space-x-4 border-t border-modal-dialog-separator px-6">
    <BaseButton
      variant="secondary"
      @click="emit('close')"
    >
      {{ t('close') }}
    </BaseButton>
  </div>
</template>
