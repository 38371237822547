import type { SettingsKeys } from '~/types/user'

export const RAW_SETTINGS_OPTIONS: Partial<Record<SettingsKeys, string[]>> = {
  NOTIFICATIONS: ['info', 'warning', 'error'],
  LANGUAGE: ['en', 'de', 'ja'],
  THEME: ['system', 'day', 'night'],
  SHOW_NUMBER_OF_UNFINISHED_TASKS: ['0', '1'],
  OPEN_DASHBOARD_ACCORDION: ['-1', '0', '1'],
  DATA_BINDING_TABLE_ORIENTATION: ['vertical', 'horizontal'],
}

export const ACCESS_TOKEN_LOCALSTORAGE_KEY = 'accessToken'
