<script setup lang="ts">
import { RAW_SETTINGS_OPTIONS } from '~/constants'

const { locale, locales: languages } = useI18n()
const { setLanguage } = useLanguage()
const userStore = useUserStore()

const NOTIFICATION_TYPES = [
  {
    key: 'info',
    label: 'allNotifications',
  },
  {
    key: 'warning',
    label: 'warningsAndErrors',
  },
  {
    key: 'error',
    label: 'errorsOnly',
  },
]

function changeLanguage(language: string) {
  userStore.language = language
  setLanguage(language)
}
function changeTheme(theme: string) {
  userStore.theme = theme
}
function changeNotification(notifications: string) {
  userStore.notifications = notifications
}
</script>

<template>
  <div class="flex flex-col gap-y-10">
    <div class="flex flex-col space-y-4">
      <h3 class="text-lg">
        {{ $t('language') }}
      </h3>
      <div
        v-for="language in languages"
        :key="language.code"
      >
        <BaseChip
          :active="language.code === locale"
          @click="changeLanguage(language.code)"
        >
          {{ language.name }}
        </BaseChip>
      </div>
    </div>
    <div class="flex flex-col space-y-4">
      <h3 class="text-lg">
        {{ $t('theme') }}
      </h3>
      <div
        v-for="theme in RAW_SETTINGS_OPTIONS.THEME"
        :key="theme"
      >
        <BaseChip
          :active="theme === userStore.theme"
          @click="changeTheme(theme)"
        >
          {{ $t(theme) }}
        </BaseChip>
      </div>
    </div>
    <div class="flex flex-col space-y-4">
      <h3 class="text-lg">
        {{ $t('notification') }}
      </h3>
      <div
        v-for="notification in NOTIFICATION_TYPES"
        :key="notification.key"
      >
        <BaseChip
          :active="notification.key === userStore.notifications"
          @click="changeNotification(notification.key)"
        >
          {{ $t(notification.label) }}
        </BaseChip>
      </div>
    </div>
  </div>
</template>
