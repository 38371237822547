<script setup lang="ts">
withDefaults(defineProps<{
  active?: boolean
}>(), {
  active: false,
})

const emit = defineEmits(['click'])
</script>

<template>
  <button
    type="button"
    class="flex items-center rounded-2xl bg-choice-chip px-4 py-2 text-sm font-semibold text-choice-chip hover:bg-choice-chip-hover"
    :class="{ 'bg-choice-chip-active': active }"
    @click="emit('click')"
  >
    <Solid20CheckIcon
      v-show="active"
      class="-ml-1 mr-2 size-5"
    />
    <slot />
  </button>
</template>
