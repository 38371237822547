<script setup lang="ts">
import General from '~/components/settings/content/General.vue'

const props = defineProps({
  content: {
    type: String,
    required: true,
    default: 'General',
  },
  labelKey: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['close'])

const { t } = useI18n()
const contentComponents: Record<string, any> = { General }

const currentComponent = computed(() => contentComponents[props.content])

function closeDialog() {
  emit('close')
}
</script>

<template>
  <div class="flex w-full flex-col">
    <SettingsHeader
      :title="t(props.labelKey)"
      :show-close-button="true"
      @close="closeDialog"
    />
    <div class="flex-1 overflow-y-auto px-6 py-4">
      <component :is="currentComponent" />
    </div>
    <SettingsFooter @close="closeDialog" />
  </div>
</template>
