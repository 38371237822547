<script setup lang="ts">
const props = withDefaults(defineProps<{
  variant: 'primary' | 'secondary' | 'text' | 'icon'
  disabled?: boolean
  autofocus?: boolean
  tabindex?: number
}>(), {
  disabled: false,
  autofocus: false,
})

const emit = defineEmits(['click'])

const classes = computed(() => {
  // TODO focus style
  if (props.disabled) {
    if (props.variant === 'primary')
      return 'px-6 text-button-primary-disabled bg-button-primary-disabled hover:bg-button-primary-disabled'
    if (props.variant === 'secondary')
      return 'px-6 text-button-secondary-disabled bg-button-secondary-disabled hover:bg-button-secondary-disabled'
    if (props.variant === 'text')
      return 'px-6 text-button-text-disabled hover:text-button-text-disabled hover:bg-transparent'
    if (props.variant === 'icon')
      return 'px-2 text-button-text-disabled'
  } else {
    if (props.variant === 'primary')
      return 'px-6 bg-button-primary text-button-primary hover:bg-button-primary-hover'
    if (props.variant === 'secondary')
      return 'px-6 bg-button-secondary text-button-secondary hover:bg-button-secondary-hover'
    if (props.variant === 'text')
      return 'px-6 text-button-text hover:text-button-text-hover hover:bg-button-text-hover'
    if (props.variant === 'icon')
      return 'px-2 hover:bg-hover'
  }
  return ''
})
</script>

<template>
  <button
    type="button"
    class="inline-flex items-center rounded-md py-2"
    :class="classes"
    :disabled="props.disabled"
    :autofocus="props.autofocus"
    :tabindex="props.tabindex"
    @click="emit('click')"
  >
    <slot />
  </button>
</template>
