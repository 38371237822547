<script setup lang="ts">
import { BeakerIcon, BellIcon, FlagIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
})
const emit = defineEmits(['change'])
const { t } = useI18n()
const icons = { BeakerIcon, BellIcon, FlagIcon }
// @ts-expect-error Because TS does not play nicely together with dynamic components
const currentIcon = computed(() => icons[props.item.icon]) as DefineComponent
</script>

<template>
  <div
    class="text-modal-dialog flex cursor-pointer items-center px-4 py-2 text-sm hover:bg-modal-dialog-navigation-hover"
    @click="emit('change', props.item)"
  >
    <component
      :is="currentIcon"
      class="mr-4 size-6 shrink-0"
      aria-hidden="true"
    />
    <span>{{ t(props.item.labelKey) }}</span>
  </div>
</template>
