<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { promiseTimeout } from '@vueuse/core'

defineOptions({ inheritAttrs: false })
const props = withDefaults(defineProps<{
  title?: string
  cancelable?: boolean
  noFooter?: boolean
  confirmText?: string
  confirmable?: boolean
  initialFocus?: HTMLElement
  fullSize?: boolean
}>(), {
  cancelable: true,
  confirmable: true,
})
// the default default is false when the type is boolean
const model = defineModel<boolean>({ default: undefined })

const emit = defineEmits(['closed', 'confirm'])

watch(model, async (newValue) => {
  if (newValue === false) {
    await promiseTimeout(300)
    emit('closed')
  }
})

function confirm() {
  // auto closing the Modal when no v-model is passed
  if (model.value === undefined)
    model.value = false
  emit('confirm')
}

function cancel() {
  if (props.cancelable)
    model.value = false
}
defineExpose({ confirm, cancel })
</script>

<template>
  <TransitionRoot
    appear
    :show="model ?? true"
    as="template"
  >
    <Dialog
      as="div"
      class="relative z-10"
      :initial-focus="props.initialFocus"
      @close="cancel"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-black/50"
          @click="cancel"
        />
      </TransitionChild>

      <div class="center fixed inset-0 p-4">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="flex max-h-full max-w-full flex-col rounded-md bg-surface shadow-lg"
            :class="{ 'size-full': props.fullSize }"
          >
            <div
              v-if="title"
              class="flex items-center px-6 pt-6"
            >
              <h2>
                {{ title }}
              </h2>
              <BaseButton
                v-if="cancelable"
                variant="icon"
                class="ml-auto"
                @click="cancel"
              >
                <Outline24XMarkIcon class="size-6" />
              </BaseButton>
            </div>

            <div
              class="max-h-full min-h-0 max-w-full flex-1 overflow-auto px-6 py-4"
              :class="{ 'size-full': props.fullSize }"
              v-bind="$attrs"
            >
              <slot />
            </div>

            <div
              v-if="!noFooter"
              class="border-t border-border px-6 py-4"
              :class="{ 'flex items-center justify-end gap-x-4': !$slots.footer }"
            >
              <slot name="footer">
                <BaseButton
                  v-if="cancelable"
                  variant="secondary"
                  @click="cancel"
                >
                  {{ $t('cancel') }}
                </BaseButton>
                <BaseButton
                  variant="primary"
                  :disabled="!confirmable"
                  @click="confirm"
                >
                  {{ props.confirmText ?? $t('save') }}
                </BaseButton>
              </slot>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
