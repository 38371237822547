import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'
import { setContext } from '@apollo/client/link/context'
import { createHttpLink, from, split } from '@apollo/client/core'
import type { ApolloClient } from '@apollo/client/core'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { onError } from '@apollo/client/link/error'

export default defineNuxtPlugin((nuxtApp) => {
  const userStore = useUserStore()

  if (import.meta.env.DEV) {
    loadDevMessages()
    loadErrorMessages()
  }

  const generateHeaders = () => {
    return {
      headers: {
        'Language-Code': userStore.language,
        'Authorization': authorizationHeader.value,
      },
    }
  }
  const headersLink = setContext(generateHeaders)
  const httpLink = createHttpLink({
    uri: `${location.protocol}//${location.host}/api/graphql/`,
    fetchOptions: {
      priority: 'high',
    },
  })
  const isSecure = location.protocol !== 'http:'
  const wsProtocol = isSecure ? 'wss:' : 'ws:'
  const wsLink = new GraphQLWsLink(
    createClient({
      url: `${wsProtocol}//${location.host}/api/graphql/`,
      connectionParams: () => generateHeaders().headers,
    }),
  )
  const errorLink = onError((err) => {
    nuxtApp.callHook('apollo:error', err)
  })
  const splitLinks = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition'
        && definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink,
  )
  const link = from([errorLink, headersLink, splitLinks])

  const { clients } = useApollo()
  const defaultClient: ApolloClient<any> = (clients as any).default
  defaultClient.setLink(link)
})
