import { LocaleHelper, LocaleManager } from '@bryntum/grid'
import BryntumEnLocale from '~/grid-configs/i18n/en.yml'
import BryntumDeLocale from '~/grid-configs/i18n/de.yml'

export function useLanguage() {
  const { setLocale } = useI18n({ useScope: 'global' })

  function initLanguage() {
    LocaleHelper.publishLocale(BryntumEnLocale)
    LocaleHelper.publishLocale(BryntumDeLocale)
  }

  function setLanguage(language: string) {
    setLocale(language)
    const capitalizedLanguage = language.charAt(0).toUpperCase() + language.slice(1)
    LocaleManager.applyLocale(capitalizedLanguage)
  }

  return {
    initLanguage,
    setLanguage,
  }
}
